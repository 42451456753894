import {
    LOCK_UI,
    UNLOCK_UI,
    UPDATE_CALENDAR,
    UPDATE_COUNTERPARTS,
    UPDATE_CONFIGURATION,
    UPDATE_FILTERS,
    // BLOCK_CALENDAR, 
    // UNBLOCK_CALENDAR,
} from '../actionsTypes'

const initialState = {
    calendar: [],
    counterpartList: [],
    configuration: 0,
    isUIEnabled: true,
    isEnabled: getBusinessRoundStatus(),
    filters: {
        categories: [],
        investmentRanges: [],
    },
    spotlightCompany: true,
}

const businessRoundsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_CALENDAR:
            return {
                ...state,
                calendar: payload
            }
        case UPDATE_COUNTERPARTS:
            return {
                ...state,
                counterpartList: payload
            }
        case LOCK_UI:
            return {
                ...state,
                isUIEnabled: false,
            }
        case UNLOCK_UI:
            return {
                ...state,
                isUIEnabled: true,
            }
        case UPDATE_CONFIGURATION:
            return {
                ...state,
                configuration: payload
            }
        case UPDATE_FILTERS:
            return {
                ...state,
                filters: payload,
            }
        // case BLOCK_CALENDAR:
        //     return {
        //         ...state,
        //         isEnabled: false
        //     }
        // case UNBLOCK_CALENDAR:
        //     return {
        //         ...state,
        //         isEnabled: true
        //     }
        default:
            return state;
    }
}

export default businessRoundsReducer

function getBusinessRoundStatus() {
    const status = window.localStorage.getItem('businessRoundEnabled')
    if (status === 'false') return false
    return true
}