import axios from 'axios';
import {
  __BR_ATTENDEES_PAGINATED__,
  __BR_MY_MEETINGS_SLOTS__,
  __BR_SLOT_BLOCK__,
  __BR_SLOT_UNBLOCK__,
  __BR_DECLINE_MEETING__,
  __BR_ACCEPT_MEETING__,
  __BR_REQUEST_MEETING__,
  __BR_AVAILABLE_MEETINGS_SLOTS__,
  __BR_CONFIGURATION__
} from '../consts/consts';
import MyStorage from '../components/Storage';
import { __API_LOGS__ } from '../consts/consts';

export async function getCounterParts() {
  try {
    const options = {
      url: __BR_ATTENDEES_PAGINATED__ + "?currentPage=1&resultsPerPage=3000&searchString=&orderByFields=Person.Company&IsBusinessRound=true",
      method: 'GET',
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      },
    };

    const { data } = await axios(options);

    const counterpartsWithExhibitor = data.Items.filter(attendee => attendee.exhibitor);
    const counterpartsWithoutExhibitor = data.Items.filter(attendee => !attendee.exhibitor);

    counterpartsWithExhibitor.sort((a, b) => a.exhibitor.name.localeCompare(b.exhibitor.name));
    counterpartsWithoutExhibitor.sort((a, b) => a.firstName.localeCompare(b.firstName));

    const counterparts = counterpartsWithExhibitor.concat(counterpartsWithoutExhibitor);
    return counterparts;
  } catch (error) {
    handleError(error, 'getCounterParts - Pedir el listado de personas habilitadas para reuniones');
    return error;
  }
}

export async function getCalendar() {
  const options = {
    url: __BR_MY_MEETINGS_SLOTS__ + MyStorage.getIdRegistration(),
    headers: {
      registrationCode: MyStorage.getPersonRegistrationCode(),
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      token: MyStorage.getJwtToken(),
    },
  };
  try {
    const { data } = await axios(options)
    const meetingDays = []
    const meetings = []

    data.forEach(slot => {
      const date = slot.meetingStartDateTime.split("T")[0]
      if (!meetingDays.includes(date)) {
        meetingDays.push(date)
      }
    })

    meetingDays.forEach(day => {
      const dayMeetings = data.filter(slot => slot.meetingStartDateTime.split("T")[0] === day)
      meetings.push({
        date: day,
        meetings: dayMeetings
      })
    })

    return meetings
  } catch (error) {
    handleError(error, 'getCalendar - Pedir mi agenda');
    console.log(error)
  }
}

export async function getBussinesRoundConfiguration() {
  try {
    const { data } = await axios.get(__BR_CONFIGURATION__)
    return Number(data[0].businessRoundType)
  } catch (error) {
    handleError(error, 'getBussinesRoundConfiguration');
    return error
  }
}

export async function blockSlot(meetingStartDateTime, meetingEndDateTime) {
  try {
    const body = {
      id: 0,
      idRegistrationRequester: MyStorage.getIdRegistration(),
      meetingStartDateTime,
      meetingEndDateTime
    }

    await axios.post(__BR_SLOT_BLOCK__, body, {
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      }
    });

    return true;
  } catch (error) {
    handleError(error, 'blockSlot - Bloquear horario');
    return error;
  }
}

export async function unblockSlot(slotId) {
  try {
    const body = {
      id: slotId,
      idRegistrationRequester: MyStorage.getIdRegistration()
    }
    await axios.post(__BR_SLOT_UNBLOCK__, body, {
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      }
    })
  } catch (error) {
    handleError(error, 'unblockSlot - Desbloquear horario');
    return error;
  }
}

export async function rejectOrCancelMeeting(slotId, responseMessage) {
  try {
    const body = {
      id: slotId,
      responseNote: responseMessage
    }
    await axios.post(__BR_DECLINE_MEETING__, body, {
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      }
    })
  } catch (error) {
    handleError(error, 'rejectOrCancelMeeting - Rechazar / Cancelar solicitud');
    return error;
  }
}

export async function acceptMeeting(slotId, responseMessage) {
  try {
    const body = {
      id: slotId,
      responseNote: responseMessage
    }
    await axios.post(__BR_ACCEPT_MEETING__, body, {
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      }
    })
  } catch (error) {
    handleError(error, 'acceptMeeting - Aceptar solicitud')
    return error;
  }
}

export async function requestMeeting(responseMessage, meetingStartDateTime, meetingEndDateTime, idRegistrationRequestee) {
  try {
    const body = {
      requestNote: responseMessage,
      meetingStartDateTime,
      meetingEndDateTime,
      idRegistrationRequestee,
      idRegistrationRequester: MyStorage.getIdRegistration()
    }
    const { data } = await axios.post(__BR_REQUEST_MEETING__, body, {
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      }
    })
    return data;
  } catch (error) {
    handleError(error, 'requestMeeting - Solicitud de reunion')
    const response = {
      success: false,
      code: error.response.data.error.code,
      message: error.response.data.error.message,
    }
    throw response;
  }
}

export async function getJointCalendar(idRegistrationRequestee) {
  try {
    const url = `${__BR_AVAILABLE_MEETINGS_SLOTS__}${MyStorage.getIdRegistration()}/${idRegistrationRequestee}`
    const { data } = await axios.get(url, {
      headers: {
        registrationCode: MyStorage.getPersonRegistrationCode(),
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        token: MyStorage.getJwtToken(),
      }
    })

    const meetingDays = []
    const meetings = []

    data.forEach(slot => {
      const date = slot.meetingStartDateTime.split("T")[0]
      if (!meetingDays.includes(date)) {
        meetingDays.push(date)
      }
    })

    meetingDays.forEach(day => {
      const dayMeetings = data.filter(slot => slot.meetingStartDateTime.split("T")[0] === day && slot.status.toLowerCase() === 'notset')
      meetings.push({
        date: day,
        meetings: dayMeetings
      })
    })

    return meetings
  } catch (error) {
    handleError(error, 'getJointCalendar - Pedido de horarios disponibles para solicitar reunión')
    return error
  }
}

function handleError(error, requestingMethod) {
  const { response, request } = error

  const logDescription = {
    level: 'ERROR',
    process: requestingMethod,
    IdEvent: MyStorage.getIdEvent(),
  };

  if (request || response) {
    logDescription.AdditionalInformation = JSON.stringify({
      registrationCode: MyStorage.getPersonRegistrationCode(),
      token: MyStorage.getJwtToken(),
    })
  }

  if (response) {
    logDescription.exceptionType = response.status + response.statusText
    logDescription.title = response.data.message
  } else if (request) {
    logDescription.title = 'No se recibio respuesta del servidor'
  } else {
    logDescription.title = 'Error de ejecución'
  }

  try {
    axios.post(__API_LOGS__, logDescription)
  } catch (err) {
    console.error(err);
  }
}