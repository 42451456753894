import Storage from '../components/Storage';
import { __IDEVENT_SETTED__ } from '../idEvent';
console.log('ejecutando consts')

export const ENABLED_EVENTS = {
  'd781f776-72e9-448f-9ebe-2da4e17b6d8f': 11163,
  '6a3ac914-4629-4c47-9ef0-cf5a4c4c0c80': 10972,
  '864fef96-d9cb-45f3-9060-182b960cdd44': 11122,
}
export const __IDEVENT_FORCED__ = __IDEVENT_SETTED__;//Defecto 0,Si aca pongo el id del evento entonces no lo toma por parámetro
export const __IS_TEST__ = false;
export const __ISDDEVELPMENT__ = false;
export const __USELOCAHOSTAPI__ = false;
export const __DOMAIN__ = __ISDDEVELPMENT__ == false ? '.kingconf.com' : 'localhost';
export const __APIURLVIRTUALEXPO__ = __USELOCAHOSTAPI__ == true ? 'http://localhost:54652/' : 'https://virtualapi.kingconf.com/';
export const __USEDEMO__ = false;
export const __ATTENDEE_PROFILE__ = "attendee";
export const __CLIENT_PROFILE__ = "hostedbuyer";
export const __EXHIBITOR_PROFILE__ = "exhibitor";
export const __VIDEOCALL_MAGIC_FRASE__ = "Te he solicitado una video llamada";
export const __ENTERSTAND_MAGIC_FRASE__ = "Visitó tu stand";
export const __VENUE_IMAGE__ = "https://kingconfimages.s3.amazonaws.com/36883789-0abb-4e61-8466-e7b4c7a70151_200922_FachadaBSAS.jpg"
export const __STREAMING_URL__ = 'https://www.youtube.com/embed/9i6lcb8dink';
export const __INTERACTIVITY_CODE__ = 'COCAL2020';
export const __BR_ROUNDS_GROUP_ONE__ = "attendee" //hostedBuyer | exhibitor | attendee
export const __BR_ROUNDS_GROUP_TWO__ = "exhibitor";
export const __ZOOM_MEETING_LINK__ = "https://zoom.us/j/98108552207?pwd=OEZOSlZDbC9TU25MYnp2WTBKRjAwQT09";
export const __ZOOM_MEETING_ID__ = "981 0855 2207";
export const __ZOOM_MEETING_PASS__ = "cipdh";
export const __ATTENDEES_ALIAS__ = "Participante/Attendee"; //Esto es el badge que se muestra en la lista de networking
export const __EXHIBITOR_ALIAS__ = "Docente/Speaker";//Esto es el badge que se muestra en la lista de networking
export const __HOSTEDBUYER_ALIAS__ = "CIPDH-UNESCO";//Esto es el badge que se muestra en la lista de networking
export const __SPEAKER_ALIAS__ = "Personaje";//Esto es el badge que se muestra en la lista de networking
export const __SHOW_EVENT_FINISHED_LANDING__ = false;
export const __SHOW_SPLASH_VIDEO__ = false;
export const __SHOW_LOBBY__ = true;
export const __DDS_HOME__ = false;
export const __FLAT_PAVILION__ = false;
export const __FORCE_SESSIONS_UPDATE_ALWAYS__ = true;

//CONFIGURATION CONSTS START
/*          LOGIN           */
export const __LOGIN_VIDEO_BACKGROUND_URL__ = "";
export const __HORIZONTAL_LOGIN__ = false;
export const __LOGIN_TITLE_ENABLED__ = false;
export const __PASSWORD_RESET_ENABLED__ = false;
export const __LOGIN_SCREEN_POSITION__ = "left";
export const __REGISTRATION_ENABLED__ = false;
export const __EMAIL_ONLY_AUTHENTICATION__ = false;
export const __ALLOW_DOWNLOAD_POSTERS__ = false;

/*          LOBBY           */
export const LOBBY_HAS_VIDEO = false;


//CONFIGURATION CONSTS END


export const __GOOGLE_ANALYTICS_TAG__ = 'G-TNWTTXS96K';
export const __CERTIFICATE_FORM__ = "https://docs.google.com/forms/d/e/1FAIpQLSc5SRTcB7PSASS-ubOMqy1TZ2PsS7S3im0iDP7-sxwK7NcTcw/viewform?entry.74033905=@@email&entry.1716438152=@@name&entry.1755343842=@@lastname";
export const __CERTIFICATE_FORM__EN = "https://docs.google.com/forms/d/e/1FAIpQLSc5SRTcB7PSASS-ubOMqy1TZ2PsS7S3im0iDP7-sxwK7NcTcw/viewform?entry.74033905=@@email&entry.1716438152=@@name&entry.1755343842=@@lastname";
export const __VALID_MAILS_FOR_CERTIFICATES__ = "" // USAR "" para no mostrar la funcion o * para habilitar todos
export const __WHATSAPP_MSG__ = "Hola,%20estoy%20visitando%20tu%20Stand%20en%20Expo%20Esencia%20Corrientes";

export const __APIKCURL__ = 'https://api.kingconf.com/api/';
export const __APIKCURL_V3__ = (__IS_TEST__ ? 'https://testapi.kingconf.com/v3.0/events/' : 'https://api.kingconf.com/v3.0/events/') + Storage.getIdEvent() + '/';
export const __LIVEAPIURL__ = 'https://liveapi.kingconf.com/api/';
export const __APIPACKAGEURL__ = "https://api.kingconf.com/api/eventpackage/" + Storage.getIdEvent();
export const __API_STANDS_URL__ = __APIKCURL_V3__ + 'exhibitionStands';
export const __API_FLOORPLANS_URL__ = __APIKCURL_V3__ + 'floorplans';
export const __API_TRIVIA_URL__ = __APIKCURL_V3__ + 'challenges/';
export const __API_SPONSORS_URL__ = __APIKCURL__ + "sponsors/" + Storage.getIdEvent()
export const __API_WALL_COUNTED_POSTS_URL = __APIKCURL_V3__ + "wall/getPostsCounted";
export const __API_HTML_STATIC_PAGES_URL = "https://api.kingconf.com/api/htmlstaticpages/" + Storage.getIdEvent();
export const __API_VIRTUAL_BASIC_INFO__ = "https://api.kingconf.com/v3.0/events/" + Storage.getIdEvent() + "/virtualBasicInfo";
export const __API_ROOMS_URL__ = __APIKCURL_V3__ + "rooms";
export const __IMAGEHANDLER_URL__ = "https://www.kingconf.com/Handlers/ImageHandler.ashx?AcImageId=";
export const __FILEHANDLER_URL__ = "https://www.kingconf.com/Handlers/EventFileHandler.ashx?AcEventFileId=";

export const __API_FEED_ROOM__ = __APIKCURL_V3__ + "chatMessagesByRoom/"
export const __API_SESSIONS_URL__ = __APIKCURL__ + "sessions/" + Storage.getIdEvent();  // 10688 tests dogui
export const __API_SESSIONS_CONTENT_URL__ = __APIKCURL__ + "sessionContent/";  // 10688 tests dogui
export const __API_SPEAKERS_URL__ = __APIKCURL__ + "speakers/" + Storage.getIdEvent();
export const __API_ATTENDEES_URL__ = __APIKCURL_V3__ + "/attendees/";
export const __API_SEND_MESSAGE_URL__ = __APIURLVIRTUALEXPO__ + "message/send/";

//BUSSINES ROUNDS
export const __BR_REQUEST_MEETING__ = __APIKCURL_V3__ + "requestBusinessRoundMeeting/";
export const __BR_DECLINE_MEETING__ = __APIKCURL_V3__ + "declineBusinessRoundMeeting/";
export const __BR_ACCEPT_MEETING__ = __APIKCURL_V3__ + "acceptBusinessRoundMeeting/";
export const __BR_RESPONSE_MEETING__ = __APIKCURL_V3__ + "responseBusinessRoundMeeting/";
export const __BR_MY_MEETINGS_SLOTS__ = __APIKCURL_V3__ + "businessRoundMyMeetingSlots/";
export const __BR_AVAILABLE_MEETINGS_SLOTS__ = __APIKCURL_V3__ + "businessRoundAvailableMeetingSlots/"; //AGENDA CONJUNTA CON UN REQUESTEE
export const __BR_SLOT_BLOCK__ = __APIKCURL_V3__ + "blockBusinessRoundMeeting/";
export const __BR_SLOT_UNBLOCK__ = __APIKCURL_V3__ + "unblockBusinessRoundMeeting/";
export const __BR_ATTENDEES_PAGINATED__ = __APIKCURL_V3__ + "attendees/attendeesPaginated";
export const __BR_CONFIGURATION__ = __APIKCURL_V3__ + 'businessRoundConfiguration?utcTimeStamp=2012-01-01'
export const __BR_CALENDAR_BLOCK__ = __APIKCURL_V3__ + "blockMeetings/";
export const __BR_CALENDAR_UNBLOCK__ = __APIKCURL_V3__ + "unblockMeetings/";

export const __BR_MY_MEETINGS__ = __APIKCURL_V3__ + "businessRoundMyMeetings/"; //TODAS LAS MEETINGS DE TODOS LOS PARTICIPANTES PERO REQUIERE IDREGISTRATION (?)
export const __BR_MEETING__ = __APIKCURL_V3__ + "businessRoundMeeting/"; //TODAS LAS MEETINGS DE TODOS LOS PARTICIPANTES

export const __API_CERTIFICATE_CHECK = __APIURLVIRTUALEXPO__ + "certificate/url/"
export const __API_VIDEO_URL__ = __APIKCURL__ + '/videos/' + Storage.getIdEvent();
export const __TWILIO_VIDEO_TOKEN__ = __APIURLVIRTUALEXPO__ + "createTwilioToken/";
export const __FILTER_IN_HOME_ONLY_HOME_HTMLPAGES__ = true; //Si esta en true muestra en la home solo las paginas que tienen "home" en PageField1
export const __API_FORUM__ = "https://api.kingconf.com/v3.0/events/" + Storage.getIdEvent() + "/forums";
export const __API_FEED_FORUM__ = __APIKCURL_V3__ + "chatMessagesByForum/"
export const __API_LEADERBOARD_URL__ = __APIKCURL_V3__ + "leaderBoard/";
export const __API_LEADERBOARDBRIEF_URL__ = __APIKCURL_V3__ + "/leaderBoardBrief/";
export const __API_REDEEM_POINTS_URL__ = __APIKCURL_V3__ + "/RedeemPointsUniferia/";
export const __API_ATTENDEES_ONLINE__ = __APIURLVIRTUALEXPO__ + "client/getUsersstatus/" + __IDEVENT_FORCED__;
export const __API_ATTENDEES_FORGOTPASSWORD__ = __APIKCURL_V3__ + "attendees/forgotPassword?email=";
export const __API_BANNERS__ = __APIKCURL__ + "banners/" + Storage.getIdEvent();
export const __API_EXHIBITOR_REPRESENTATIVE__ = __APIKCURL_V3__ + "exhibitor/";
export const __API_LOGS__ = __APIKCURL_V3__ + "/log";
