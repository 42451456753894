import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createStore } from 'redux';
import allReducers from './redux/reducers';
import { Provider } from 'react-redux';
import './assets/main.scss';
import { configureStore } from './redux/store';
import 'react-image-lightbox/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import SignalRSocket from './components/SignalrSocket';
import Utils from './components/Utils';
import Storage from './components/Storage';
import ApiCalls from './components/ApiCalls';

// const store = createStore(allReducers,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const socket = new SignalRSocket();
export const store = configureStore(undefined, socket);

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));
console.log(`Virtual Congress version: ${process.env.REACT_APP_VIRTUAL_CONGRESS_VERSION}`);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter  >
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
