import { getCalendar, getCounterParts } from '../../services/bussinesRounds.service'
import { UPDATE_CALENDAR, UPDATE_COUNTERPARTS, UPDATE_CONFIGURATION, LOCK_UI, UNLOCK_UI, UPDATE_FILTERS } from '../actionsTypes'

export const updateCalendar = () => {
    return async (dispatch) => {
        try {
            const payload = await getCalendar()

            dispatch({
                type: UPDATE_CALENDAR,
                payload
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export const updateCounterparts = () => {
    return async (dispatch) => {
        try {
            const payload = await getCounterParts()

            dispatch({
                type: UPDATE_COUNTERPARTS,
                payload
            })
        } catch (error) {
            console.log(error);
        }
    }
}

export const lockUI = () => {
    return {
        type: LOCK_UI
    }
}

export const unLockUI = () => {
    return {
        type: UNLOCK_UI
    }
}

export const setCalendar = (myMeetings) => {
    return {
        type: UPDATE_CALENDAR,
        payload: myMeetings
    }
}

export const setCounterparts = (myCounterparts) => {
    return {
        type: UPDATE_COUNTERPARTS,
        payload: myCounterparts
    }
}

export const setConfiguration = (configuration) => {
    return {
        type: UPDATE_CONFIGURATION,
        payload: configuration
    }
}

export const setFiltersData = (categories, investmentRanges) => {
    const categoriesTransformed = categories.map((category, index) => ({
        id: index + 1,
        label: category,
        checked: true,
    }));
    categoriesTransformed.unshift({ id: 0, label: 'Todas los rubros', checked: true });
    const investmentRangesTransformed = {
        lowEnd: parseInt(investmentRanges[0]),
        highEnd: parseInt(investmentRanges[1]),
    };
    return {
        type: UPDATE_FILTERS,
        payload: { categories: categoriesTransformed, investmentRanges: investmentRangesTransformed }
    }
}
// export const blockCalendar = (message) => {
//     return async (dispatch) => {
//         try {
//             const { data } = await blockCalendar(message)
//             Storage.setBusinessRoundStatus(false)
//             dispatch({
//                 type: BLOCK_CALENDAR,
//             })
//         } catch (error) {
//             console.log(error)
//         }
//     }
// }

// export const unBlockCalendar = () => {
//     return async (dispatch) => {
//         try {
//             await unBlockCalendar()
//             Storage.setBusinessRoundStatus(true)
//             dispatch({
//                 type: UNBLOCK_CALENDAR,
//             })
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }