import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import thunk from 'redux-thunk'

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

export function configureStore(initialState, socket) {
  // const store = createStore(allReducers,
  //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middlewares)
      // ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );

  sagaMiddleware.run(sagas, socket);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }



  return store;
}
